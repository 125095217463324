import styled from "styled-components";
//import { Lock } from "@styled-icons/material";
//import { iconPaths } from "../contants";
//import MyIcon from "../assets/footerInstagram.svg";
//import backgroundImage from "../assets/smsBackground.jpeg";
//import { colors } from "../contants";

export const infoText = styled.h3`
  color: white;
  font-size: 1.8rem;
`;

export const infoCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-top: 4.5rem;
  margin-bottom: 1rem;
  align-items: center;
  background-color: rgb(40, 40, 50);
`;

export const infoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

export const StyledIcon = styled.div`
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
`;

export const infoContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgb(40, 40, 50);

  @media (max-width: 1400px) {
    height: 54rem;
  }
  @media (max-width: 600px) {
    height: 94rem;
  }
`;

export const infoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding = 10px;
  margin: 20px;
  
`;

export const infoImg = styled.img`
  height: 100px;
  width: 100px;
`;

export const infoTitle = styled.h3`
  font-size: 16px;
  margin-top: 10px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
`;

export const infoContent = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
`;
