import styled from "styled-components";
import { colors } from "../contants";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  transition: padding 0.5s ease, background 0.5s ease;

  svg {
    transition: width 0.5s ease;
  }

  a {
    transition: all 0.5s ease;
  }

  ${(props) =>
    props.sticky &&
    `
    padding: 5px 0;
    background: rgba(18, 18, 28, 0.89);
    backdrop-filter: blur(9.7px);
    -webkit-backdrop-filter: blur(9.7px);
    border-bottom: 1px solid ${colors.border};

    @media (max-width: 600px) {
      padding: 1rem 0;
    }

    & > div {
      padding: 0 2rem;
    }

    svg {
      width: 4.5rem;

      @media (max-width: 600px) {
        width: 4rem !important;
        height: 100% !important;
      }
    }

    .header-button {
      padding: 1.4rem 2.2rem;
      @media (max-width: 600px) {
        padding: 0.1rem 0.1rem;
      }
    }
  `}
`;

export const HeaderContainer = styled.div`
  width: 95%;
  max-width: 140rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-image: url(${(props) => props.image});
  transition: all 0.5s ease;
`;

export const HeaderLogo = styled.img`
  width: 5rem;
`;

export const HeaderButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  padding-right: 6rem;
  @media only screen and (max-width: 600px) {
    gap: 2rem;
    padding-right: 2rem;
  }
`;

export const HeaderButton = styled.a`
  font-size: 1.2rem;

  font-weight: 20;
  color: white;
  transition: color 0.5s ease;
  cursor: pointer;

  &:hover {
    color: red;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const HeaderButton1 = styled.a`
  font-size: 1.2rem;

  font-weight: 20;
  color: white;
  transition: color 0.5s ease;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;
