import styled from "styled-components";
import { colors } from "../contants";
//import { FiPhone } from "react-icons/fi";
import { GoMail } from "react-icons/go";

import { IoLocationOutline } from "react-icons/io5";
import { BsInstagram } from "react-icons/bs";

import { FiPhone, FiEmail, FiInstagram, FiMapPin } from "react-icons/fi";

//IoLocationOutline
//BsInstagram

export const Cont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  margin-bottom: 1rem;

  background-color: rgb(40, 40, 50);
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: start;
  background-color: rgb(40, 40, 50);

  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const IconBox = styled.div`
  width: 15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
`;

export const IconButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 5px;
  background-color: green;
  &:hover {
    background-color: ${colors.secondaryColor};
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin-top: 1rem;
  padding-left: 2rem;
`;

export const FooterText = styled.h3`
  color: white;
  font-size: 1.3rem;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const FooterText2 = styled.h3`
  color: white;
  font-size: 1.6rem;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const FooterImg = styled.img`
  width: 9rem;
  margin-bottom: 3rem;
`;

export const ContactInfo = styled.h3`
  color: white;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
  font-size: 1.4rem;
  margin-top: 0rem;
  display: flex;
  align-items: center;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
  }
  margin-left: 1rem;
`;

export const PhoneIcon = styled(FiPhone)`
  font-size: 1.5rem;
  margin-right: 1rem;
  fill: white;
`;

export const EmailIcon = styled(GoMail)`
  font-size: 1.5rem; /* Adjust the icon size as needed */
  margin-right: 1rem; /* Add spacing between the icon and email address */
  fill: white;
`;

export const LocationIcon = styled(FiMapPin)`
  font-size: 1.5rem; /* Adjust the icon size as needed */
  margin-right: 1rem; /* Add spacing between the icon and email address */
  fill: white;
`;

export const instagramIcon = styled(BsInstagram)`
  font-size: 1.5rem; /* Adjust the icon size as needed */
  margin-right: 1rem; /* Add spacing between the icon and email address */
  fill: white !important;
`;

export const GoogleMapContainer = styled.div`
  width: 600px; /* Set the desired width for the map */
  height: 450px; /* Set the desired height for the map */
  @media only screen and (max-width: 600px) {
    width: 300px; /* Set the desired width for the map */
    height: 225px;
    margin-bottom: 5rem;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const FooterTitle = styled.h3`
  color: white;
  font-size: 1.8rem;
  align-items: center;
`;

export const titleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
