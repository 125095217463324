import * as sc from "../styled/scFooter";
import Logo from "../assets/logoYelken.png";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Twitter } from "@styled-icons/boxicons-logos/Twitter";
import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin";
import { footerData } from "../contants/index.js";
//import { Phone } from "@styled-icons/boxicons-solid/Phonei";

const Footer = () => (
  <sc.Cont>
    <sc.titleRow>
      <sc.FooterTitle>İletişim</sc.FooterTitle>
    </sc.titleRow>

    <sc.FooterContainer id="footer">
      {/* Map over the footerData array and render each item */}

      <sc.FooterLeft>
        <sc.FooterRow>
          <sc.FooterImg src={Logo} />
        </sc.FooterRow>

        {footerData.map((item, index) => (
          <sc.FooterRow key={index}>
            <sc.ContactInfo>
              {/* Render the icon using the icon component from the item */}
              <item.icon />
              {item.href ? (
                <sc.StyledLink
                  href={item.href}
                  target="_blank"
                  className="custom-link"
                >
                  {item.content}
                </sc.StyledLink>
              ) : (
                item.content
              )}
            </sc.ContactInfo>
          </sc.FooterRow>
        ))}
      </sc.FooterLeft>

      <sc.FooterLeft>
        <sc.GoogleMapContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3062.181389070076!2d33.45722247532478!3d39.870174688724326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4081e18d08191e07%3A0x4101cd3748c94da7!2sYELKEN%20OTEL!5e0!3m2!1str!2str!4v1694265747619!5m2!1str!2str"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </sc.GoogleMapContainer>
      </sc.FooterLeft>
    </sc.FooterContainer>
  </sc.Cont>
);

export default Footer;
