import * as sc from "../styled/scHero";
import YelkenYazi from "../assets/yelkenYazi.png";
import { useState, useEffect } from "react";
import { scrollTo } from "../animation";

const Hero = () => {
  const [sticky, setSticky] = useState(false);
  const [active, setActive] = useState(false);

  const stickyHeaderFn = () => {
    const scrollY = window.scrollY;
    if (scrollY >= 50 && !sticky) setSticky(true);
    else if (scrollY < 50 && sticky) setSticky(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", stickyHeaderFn);
    return () => document.removeEventListener("scroll", stickyHeaderFn);
  });

  return (
    <sc.HeroWrapper>
      <sc.HeroContainer>
        <sc.TextContainer>
          <sc.HeroLogoImage
            src={YelkenYazi}
            alt="YELKEN HOTEL"
          />

          <h3>
            Kırıkkale'nin Kalbinde Lüks ve Konforun Buluştuğu Yelken Hotel'e Hoş
            Geldiniz.
          </h3>
        </sc.TextContainer>

        <sc.ButtonContainer>
          <sc.VideoButton
            onClick={() => {
              setActive(false);
              scrollTo("#footer");
            }}
          >
            Bize Ulaşın.
          </sc.VideoButton>
        </sc.ButtonContainer>
      </sc.HeroContainer>
    </sc.HeroWrapper>
  );
};

export default Hero;
