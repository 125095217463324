import styled from "styled-components";
import { colors } from "../contants";

import backgroundImage from "../assets/intro-bg.jpg";
import backgroundImage2 from "../assets/yelkenHotel/IMG_4839.jpg";
import backgroundImage3 from "../assets/yelkenHotel/IMG_4845.jpg";
import backgroundImage4 from "../assets/yelkenHotel/IMG_3830.JPG";
import backgroundImage5 from "../assets/AdobeStock_104135921_Preview.jpeg";
import backgroundImage6 from "../assets/AdobeStock_286932731_Preview.jpeg";

import backgroundImage7 from "../assets/AdobeStock_47024555_Preview.jpeg";

import backgroundImage8 from "../assets/AdobeStock_86619184_Preview.jpeg";
import backgroundImage9 from "../assets/yelkenHotel/IMG_3865.JPG";

export const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  height: 46rem;
  flex-direction: column;
  justify-content: center;

  display: flex;

  @media (max-width: 600px) {
    height: 40rem;
  }

  background: rgba(6, 12, 34, 0.8);
`;

export const HeroLogoImage = styled.img`
  width: 700px;
  height: 100px;

  @media (max-width: 600px) {
    width: 280px;
    height: 40px;
  }
`;

export const HeroText = styled.h3`
  color: white;
  font-size: 5rem;
`;

export const HeroText2 = styled.h3`
  color: white;
  font-size: 1.2rem;
  background-color: yellow;
`;

export const Logo = styled.div`
  position: absolute;
  width: 50%;
  @media (max-width: 600px) {
    width: 80%;
    top: 0%;
  }
  display: flex;
  justify-content: center;
  top: 10%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 0%;
  color: white;
  padding-left: 22rem;
  @media (max-width: 600px) {
    top: 0%;
    padding: 1rem;
    align-items: center;
  }
`;

export const HeroWrapper = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${backgroundImage9});
  position: relative;

  background-attachment: fixed;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 70%;

  @media (max-width: 600px) {
    top: 70%;
  }
`;

export const VideoButton = styled.a`
  color: ${(props) => (props.$active ? "black" : colors.textColor)};
  background-color: ${(props) =>
    props.$active ? colors.primaryColor : colors.dark300};
  font-size: 1.2rem;
  font-weight: 400;
  border-radius: 1rem;
  transition: background-color 0.5s ease;
  padding: 1rem 3rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  ${(props) =>
    !props.$active &&
    `
    &:hover {
      background-color: red;
    }
  `}

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;
