import gsap, { Power2 } from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const scrollTo = (to) =>
  gsap.to(window, {
    duration: 1,
    scrollTo: to,
    ease: Power2.easeInOut,
  });
