import { useState, useEffect } from "react";
import * as sc from "../styled/scHeader";
import Logo from "../assets/logoYelken.png";
import { scrollTo } from "../animation";

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [active, setActive] = useState(false);

  const stickyHeaderFn = () => {
    const scrollY = window.scrollY;
    if (scrollY >= 50 && !sticky) setSticky(true);
    else if (scrollY < 50 && sticky) setSticky(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", stickyHeaderFn);
    return () => document.removeEventListener("scroll", stickyHeaderFn);
  });

  return (
    <sc.HeaderWrapper className="header" sticky={sticky}>
      <sc.HeaderContainer>
        <sc.HeaderLogo src={Logo} />
        <sc.HeaderButtonGroup>
          <sc.HeaderButton
            onClick={() => {
              setActive(false);
              scrollTo("#home");
            }}
            className="header-button"
            target="_blank"
            $outline
            $disabledOnMobile
          >
            Anasayfa
          </sc.HeaderButton>
          <sc.HeaderButton
            onClick={() => {
              setActive(false);
              scrollTo("#gallery");
            }}
            className="header-button"
            target="_blank"
            $outline
            $disabledOnMobile
          >
            Galeri
          </sc.HeaderButton>
          <sc.HeaderButton1
            className="header-button"
            onClick={() => {
              setActive(false);
              scrollTo("#olanaklar");
            }}
            target="_blank"
          >
            Olanaklar
          </sc.HeaderButton1>
          <sc.HeaderButton1
            className="header-button"
            onClick={() => {
              setActive(false);
              scrollTo("#footer");
            }}
            target="_blank"
          >
            İletişim
          </sc.HeaderButton1>
        </sc.HeaderButtonGroup>
      </sc.HeaderContainer>
    </sc.HeaderWrapper>
  );
};

export default Header;
