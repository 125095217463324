import styled from "styled-components";

export const TitleWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  padding: 1rem 0;
  justify-content: center;

  color: black;
`;

export const Gallery = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  background-color: #fffff0;
  background-color: #ffff;
`;

export const GalleryWrapper = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  max-height: 900px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid rgb(40, 40, 50);
  box-shadow: #2b15453d 1px 10px 10px 5px;
`;

export const Title = styled.h3`
  font-size: 2rem;
`;

export const GalleryText = styled.h3`
  color: black;
  font-size: 1.8rem;
`;
