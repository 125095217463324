import Logo from "../assets/hotel.jpg";
import img3798 from "../assets/yelkenHotel/IMG_3798.jpg";
import img3830 from "../assets/yelkenHotel/IMG_3830.JPG";
import img3833 from "../assets/yelkenHotel/IMG_3833.JPG";
import img3836 from "../assets/yelkenHotel/IMG_3836.JPG";
import img3837 from "../assets/yelkenHotel/IMG_3837.JPG";
import img3847 from "../assets/yelkenHotel/IMG_3847.JPG";
import img3851 from "../assets/yelkenHotel/IMG_3851.JPG";
import img3853 from "../assets/yelkenHotel/IMG_3853.JPG";
import img3854 from "../assets/yelkenHotel/IMG_3854.JPG";
import img3861 from "../assets/yelkenHotel/IMG_3861.JPG";
import img3865 from "../assets/yelkenHotel/IMG_3865.JPG";

import img4866 from "../assets/yelkenHotel/IMG_4866.jpg";
import img4834 from "../assets/yelkenHotel/IMG_4834.jpg";
import img4840 from "../assets/yelkenHotel/IMG_4840.jpg";
import img4843 from "../assets/yelkenHotel/IMG_4843.jpg";

import img4845 from "../assets/yelkenHotel/IMG_4845.jpg";
import img4847 from "../assets/yelkenHotel/IMG_4847.jpg";

import img4850 from "../assets/yelkenHotel/IMG_4850.jpg";
import img4857 from "../assets/yelkenHotel/IMG_4857.jpg";
import img4854 from "../assets/yelkenHotel/IMG_4854.jpg";

import map from "../assets/icons/map.svg";
import clock from "../assets/icons/clock.svg";
import peopleGroup from "../assets/icons/peopleGroup.svg";
import heartLike from "../assets/icons/heartLike.svg";

import airConditioner from "../assets/icons/airCond.svg";
import balcony from "../assets/icons/balcony.svg";
import beverage from "../assets/icons/beverage.svg";

import bottle from "../assets/icons/bottle.svg";

import cafe from "../assets/icons/cafe.svg";

import drink from "../assets/icons/drink.svg";
import park from "../assets/icons/park.svg";

import balconyRoom from "../assets/icons/balconyRoom.svg";
import balconyR from "../assets/icons/balconyR.svg";

import snack from "../assets/icons/snack.svg";

import wifi from "../assets/icons/wifi.svg";
import { FiPhone, FiMapPin, FiMail, FiInstagram } from "react-icons/fi";

export const images = [
  {
    original: img4834,
    thumbnail: img4834,
  },
  {
    original: img4854,
    thumbnail: img4854,
  },
  {
    original: img4857,
    thumbnail: img4857,
  },
  {
    original: img4850,
    thumbnail: img4850,
  },
  {
    original: img4866,
    thumbnail: img4866,
  },
  {
    original: img4840,
    thumbnail: img4840,
  },
  {
    original: img3798,
    thumbnail: img3798,
  },
  {
    original: img3830,
    thumbnail: img3830,
  },
  {
    original: img3833,
    thumbnail: img3833,
  },
  {
    original: img3836,
    thumbnail: img3836,
  },
  {
    original: img3837,
    thumbnail: img3837,
  },
  {
    original: img3847,
    thumbnail: img3847,
  },
  {
    original: img4843,
    thumbnail: img4843,
  },
  {
    original: img4845,
    thumbnail: img4845,
  },
  {
    original: img4847,
    thumbnail: img4847,
  },

  {
    original: img3851,
    thumbnail: img3851,
  },
  {
    original: img3853,
    thumbnail: img3853,
  },
  {
    original: img3854,
    thumbnail: img3854,
  },
  {
    original: img3861,
    thumbnail: img3861,
  },
  {
    original: img3865,
    thumbnail: img3865,
  },
];

export const eventLogo = Logo;

export const navigationItems = [
  {
    name: "Anasayfa",
    to: "#home",
  },
  {
    name: "Galeri",
    to: "#gallery",
  },
  {
    name: "İletişim",
    to: "#footer",
  },
  {
    name: "Olanaklar",
    to: "#olanaklar",
  },
];

export const colors = {
  red: "#f82249",
  darkBlue: "#0e1b4d",

  primaryBG: "fffff",
  secondaryBackground: "#f6f7fd",

  primaryColor: "white",
  secondaryColor: "#B66CD2",
  secondaryColorLight: "#fffefe",
  secondaryBackground: "#f2f5f3",
  textColor: "black",
  borderColor: "rgba(255, 255, 255, .1)",
  popupColor: "#F0F3FB",
  popupOverlayColor: "rgba(0, 0, 0, .3)",
  dark300: "white",
  dark400: "black",
  pink1: "#433CD5",
};

export const infoData = [
  {
    icon: park,
    title: "OTOPARK",
    content: "Ücretsiz Otopark Hizmeti",
    content1: "",
  },
  {
    icon: wifi,
    title: "WIFI",
    content: "Hızlı, Kablosuz İnternet",
    content1: "",
  },
  {
    icon: cafe,
    title: "CAFE",
    content: "Lezzetli Atıştırmalıklar",
    content1: "",
  },
  {
    icon: airConditioner,
    title: "KLİMA",
    content: "Maksimum Konfor ve Serinlik",
    content1: "",
  },
  {
    icon: balconyRoom,
    title: "BALKONLU ODA",
    content: "Manzaralı, Balkonlu Odalar",
    content1: "",
  },

  {
    icon: drink,
    title: "İKRAMLAR",
    content: "Odanızda Çay ve Kahve Keyfi",
    content1: "",
  },
];

const iconMap = {
  phone: FiPhone,
  location: FiMapPin,
  email: FiMail,
  instagram: FiInstagram,
};

export const footerData = [
  {
    icon: iconMap.phone,
    title: "Phone Number",
    content: "0318 2240771",
    href: "tel:+900318 2240771", // Clickable phone number link
  },
  {
    icon: iconMap.phone,
    title: "Phone Number",
    content: "0553 2240771",
    href: "tel:+900553 2240771", // Clickable phone number link
  },
  {
    icon: iconMap.location,
    title: "Location",
    content: "267 sokak no 8 Yenişehir / Yahşihan Kırıkkale",
    href: "https://goo.gl/maps/7aBZctZCAYLaYyre9", // Clickable location link
  },
  {
    icon: iconMap.email,
    title: "Email",
    content: "yelkenotel71@gmail.com",
    href: "mailto:yelkenotel71@gmail.com", // Clickable email link
  },
  {
    icon: iconMap.instagram,
    title: "Instagram",
    content: "@yenişehiryelkenhotel",
    href: "https://www.instagram.com/yenisehir.yelkenotel/", // Clickable Instagram link
  },
];

//import AyseBegumImage from "../assets/ayseOnbasi.png";
