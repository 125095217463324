import React from "react";
import styled from "styled-components";

export const StyledIcon = styled.img`
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  filter: invert(100%);
`;

// <img src={iconPath} alt={iconName} />
//<img src={iconName} />
export const MyComponent = ({ iconName }) => {
  const iconPath = `../assets/${iconName}.svg`;

  return (
    <div>
      <StyledIcon src={iconName} />
    </div>
  );
};

export default MyComponent;

/*
export const IconContainer = styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
`;

///Users/benguozbek/Documents/GitHub/sms23/src/assets/instagram.svg
//const iconPath = `../assets/${name}.svg`;
export const Icon = ({ name, color, size }) => {
  const iconPath = `../../assets/${name}.svg`;

  const IconSvg = React.lazy(() => import(`${iconPath}`));
  return (
    <IconContainer style={{ color: color, fontSize: size }}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <IconSvg viewBox="0 0 24 24" />
      </React.Suspense>
    </IconContainer>
  );
};

export default Icon;
*/
