// WhatsAppButton.js
import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

const BaseButtonStyles = `
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  text-align: center;
  font-size: 36px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px); /* Adjust the initial position */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const rotateAndScale = keyframes`
0% {
    transform: scale(0) rotate(0deg);
  }
  25% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
  }
  75% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
  }
`;

const rotatePause = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const delay = keyframes`
  0%, 100% {
    opacity: 1;
  }
  10%, 90% {
    opacity: 0;
  }
`;

const StyledWhatsappButton = styled.a`
  ${BaseButtonStyles}
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  animation: ${fadeIn} 5s ease;
`;

const StyledCallButton = styled.a`
  ${BaseButtonStyles}
  bottom: 110px;
  right: 40px;
  background-color: #34b7f1; /* Change to your preferred color */
  color: #fff;
  animation: ${fadeIn} 5s ease;
`;

const StyledLocationButton = styled.a`
  ${BaseButtonStyles}
  bottom: 180px;
  right: 40px;
  background-color: #ff9800; /* Change to your preferred color */
  color: #fff;
  animation: ${fadeIn} 5s ease;
`;

const WhatsappIcon = styled.i`
  margin-top: 0px;
`;

const CallIcon = styled.i`
  margin-top: 0px;
`;

const LocationIcon = styled.i`
  margin-top: 0px;
`;

const WhatsAppButton = () => {
  const whatsappEncodedMessage = encodeURIComponent(
    "Merhabalar, Yelken Otel hakkında bilgi alabilir miyim ?"
  );

  return (
    <>
      <StyledWhatsappButton
        href={`https://api.whatsapp.com/send?phone=905532240771&text=${whatsappEncodedMessage}`}
        target="_blank"
      >
        <WhatsappIcon className="fa fa-whatsapp" />
      </StyledWhatsappButton>

      <StyledCallButton href="tel:+905532240771">
        <CallIcon className="fa fa-phone" />
      </StyledCallButton>

      <StyledLocationButton
        href="https://maps.app.goo.gl/k65EPrbudYqM9AbX6"
        target="_blank"
      >
        <LocationIcon className="fa fa-map-marker" />
      </StyledLocationButton>
    </>
  );
};

export default WhatsAppButton;
