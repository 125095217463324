import Hero from "./components/Hero";
import Header from "./components/Header";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import Info from "./components/Info";
import WhatsApp from "./components/Whatsapp";

//import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  return (
    <main>
      <Header />

      <Hero />

      <Info />
      <Gallery />
      <WhatsApp />

      <Footer />
    </main>
  );
};

export default App;
