import ImageGallery from "react-image-gallery";
import { images } from "../contants";
import * as sc from "../styled/scGallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../styled/Gallery.css";

function Gallery() {
  return (
    <sc.Gallery id="gallery">
      <header>
        <sc.TitleWrapper>
          <h1>Galeri</h1>
        </sc.TitleWrapper>
      </header>
      <sc.GalleryWrapper>
        <ImageGallery
          items={images}
          autoPlay={false}
          showThumbnails={true}
          style={{ width: 500, height: 600 }}
        />
      </sc.GalleryWrapper>
    </sc.Gallery>
  );
}

export default Gallery;
